import React, { useEffect, useRef } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useHoverDirty } from 'react-use'

const AccordionItem = ({
  isActive,
  onSelect,
  index,
  hasNoDescription,
  children,
  onHoverChange,
}) => {
  const itemRef = useRef(null)
  const isHovered = useHoverDirty(itemRef)

  useEffect(() => {
    onHoverChange(index, isHovered)
  }, [isHovered])

  function handleSelect() {
    onSelect(index)
  }

  return (
    <li
      className={cx('section-list-item --accordion', { 'is-active': isActive })}
      ref={itemRef}
    >
      <button
        type="button"
        className={cx({ 'is-centered': hasNoDescription })}
        onClick={handleSelect}
      >
        {children}
      </button>
    </li>
  )
}

AccordionItem.propTypes = {
  isActive: PropTypes.bool,
  hasNoDescription: PropTypes.bool,
  children: PropTypes.node,
  index: PropTypes.number,
  onSelect: PropTypes.func,
  onHoverChange: PropTypes.func,
}

export default AccordionItem
