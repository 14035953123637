import React, { useState, useEffect, useRef } from 'react'
import cx from 'classnames'
import { useInView } from 'react-intersection-observer'
import { gsap } from 'gsap'
import { debounce, isNil } from 'lodash'

import UIOverlay from 'components/UIOverlay'
import BasicVideo from 'components/BasicVideo'
import SDKIcon from 'images/landing-page/sdk-icon.svg'
import AOHeaderImage from 'images/landing-page/a+o-header.png'
import AOBodyImage from 'images/landing-page/a+o-body.png'
import SDKVideo from 'video/sdk-ao.mp4'
import SDKPoster from 'video/sdk-poster.jpg'

const SDK = () => {
  const iconRef = useRef(null)
  const iconContainerRef = useRef(null)
  const pulseRef = useRef(null)
  const browserRef = useRef(null)
  const playerRef = useRef(null)
  const timelineRef = useRef(null)
  const videoRef = useRef(null)
  const uiRef = useRef(null)
  const { ref, inView } = useInView({
    threshold: 0.75,
    triggerOnce: true,
  })
  const [showPlayer, setShowPlayer] = useState(false)
  const [showPulse, setShowPulse] = useState(false)

  useEffect(() => {
    timelineRef.current = gsap.timeline()

    return () => {
      window.removeEventListener('resize', debounce(onResize, 100), false)
      timelineRef.current.kill()
      timelineRef.current = null
      setShowPulse(false)
      setShowPlayer(false)
    }
  }, [])

  useEffect(() => {
    if (inView) {
      window.addEventListener('resize', debounce(onResize, 100), false)
      onStartTimeline()
      videoRef.current.load()
    }
  }, [inView])

  function onResize() {
    if (!isNil(videoRef.current)) {
      videoRef.current.pause()
      videoRef.current.currentTime = 0
      timelineRef.current.kill()
      timelineRef.current.set(
        [
          iconRef.current,
          playerRef.current,
          pulseRef.current,
          iconContainerRef.current,
          videoRef.current,
        ],
        { clearProps: 'all' },
      )
      setShowPulse(false)
      setShowPlayer(false)
      timelineRef.current = gsap.timeline()
      onStartTimeline()
    }
  }

  function onStartTimeline() {
    const PADDING = 10

    timelineRef.current
      .to(iconRef.current, {
        delay: 0.5,
        duration: 0.7,
        x: browserRef.current.offsetWidth / 2 - PADDING,
        top: '0',
        y: videoRef.current.offsetHeight / 2 + iconRef.current.offsetHeight / 2,
      })
      .to(
        iconRef.current,
        {
          duration: 0.5,
          scale: 0.65,
        },
        '-=0.1',
      )
      .to(
        playerRef.current,
        {
          duration: 0.9,
          maxHeight: videoRef.current.offsetHeight + 20,
          onComplete: () => {
            setShowPlayer(true)
          },
        },
        '-=1.2',
      )
      .to(pulseRef.current, {
        duration: 0.9,
        scale: 300,
        onStart: () => {
          setShowPulse(true)
        },
      })
      .to(
        iconContainerRef.current,
        {
          duration: 0.25,
          opacity: 0,
        },
        '-=0.75',
      )
      .to(
        pulseRef.current,
        {
          duration: 0.8,
          opacity: 0,
        },
        '-=0.5',
      )
      .to(
        [videoRef.current, uiRef.current],
        {
          duration: 0.5,
          opacity: 1,
          onStart: () => {
            videoRef.current.play()
          },
        },
        '-=0.35',
      )
  }

  return (
    <div className="sdk-container" ref={ref}>
      <div ref={browserRef} className="sdk-browser">
        <div className="sdk-header">
          <img src={AOHeaderImage} alt="A + O Header" />
        </div>
        <div className="sdk-body">
          <div
            ref={playerRef}
            className={cx('sdk-animation', {
              'is-active': showPlayer,
            })}
          >
            <div className="sdk-video-container">
              <div className="sdk-video">
                <BasicVideo
                  src={SDKVideo}
                  poster={SDKPoster}
                  videoRef={videoRef}
                />
                <UIOverlay uiRef={uiRef} />
              </div>
            </div>
            <span
              ref={pulseRef}
              className={cx('sdk-pulse', {
                'is-active': showPulse,
              })}
            />
          </div>
          <img src={AOBodyImage} alt="A + Body" />
        </div>
      </div>
      <div ref={iconRef} className="sdk-icon">
        <div ref={iconContainerRef} className="sdk-icon-container">
          <SDKIcon />
        </div>
      </div>
    </div>
  )
}

export default SDK
