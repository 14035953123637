import React from 'react'

import SDK from 'components/SDK'

const Integration = () => (
  <>
    <div className="rect --purple" />
    <section className="section --purple">
      <div className="section-wrapper">
        <div className="section-container">
          <div className="section-content">
            <h4 className="section-label">Integration</h4>
            <h2 className="section-subheading">Start Streaming In Minutes</h2>
            <p className="section-description">
              Integrate on your website or app with 6 lines of code. LIVE offers
              a simple SDK and requires minimal developer support.
            </p>
          </div>
          <div className="section-animation">
            <SDK />
          </div>
        </div>
      </div>
    </section>
  </>
)

export default Integration
