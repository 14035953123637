import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { isEqual } from 'lodash'
import { useState } from 'react'
import { useInterval } from 'react-use'

const useAccordionState = (lastIndex, changeDelay) => {
  const breakpoints = useBreakpoint()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [accordionChangeDelay, setaccordionChangeDelay] = useState(changeDelay)

  useInterval(
    () => {
      setCurrentIndex(prevIndex => {
        if (isEqual(prevIndex, lastIndex)) {
          return 0
        }
        return prevIndex + 1
      })
    },
    breakpoints.md ? accordionChangeDelay : null,
  )

  function onAccordionChange(index) {
    setCurrentIndex(index)
    setaccordionChangeDelay(null)
  }

  function onAccordionHoverChange(index, hovered) {
    if (isEqual(currentIndex, index)) {
      setaccordionChangeDelay(hovered ? null : changeDelay)
    }
  }

  return [currentIndex, onAccordionChange, onAccordionHoverChange]
}

export default useAccordionState
