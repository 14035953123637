import React from 'react'
import { Link } from 'gatsby'
import { InView } from 'react-intersection-observer'
import PropTypes from 'prop-types'

import BasicVideo from 'components/BasicVideo'
import FooterOneVideo from 'video/footer/footer-1.mp4'
import PosterOne from 'video/footer/poster-1.jpg'
import FooterTwoVideo from 'video/footer/footer-2.mp4'
import PosterTwo from 'video/footer/poster-2.jpg'
import FooterThreeVideo from 'video/footer/footer-3.mp4'
import PosterThree from 'video/footer/poster-3.jpg'
import FooterFourVideo from 'video/footer/footer-4.mp4'
import PosterFour from 'video/footer/poster-4.jpg'
import FooterFiveVideo from 'video/footer/footer-5.mp4'
import PosterFive from 'video/footer/poster-5.jpg'
import FooterSixVideo from 'video/footer/footer-6.mp4'
import PosterSix from 'video/footer/poster-6.jpg'
import Tiers from '../SectionTiers'
import { GA_EVENT_SOURCES, SECTION_IDS } from '../constants'

const Actions = ({ toggleShowBookDemoModal }) => {
  function handleBookDemo() {
    toggleShowBookDemoModal(GA_EVENT_SOURCES.PRICING)
  }

  return (
    <>
      <div className="rect" />
      <section className="section --footer">
        <div className="section-actions">
          <h1 id={SECTION_IDS.PRICING.id}>Ready to go Live?</h1>
          <div className="sections-actions-container">
            <Link
              className="button --primary"
              to="/signup"
              state={{ srouce: GA_EVENT_SOURCES.PRICING }}
            >
              Get Started
            </Link>
            <button
              className="button --secondary"
              onClick={handleBookDemo}
              type="button"
            >
              Book a Demo
            </button>
          </div>
        </div>
      </section>
      <Tiers />
      <div className="footer-block-container">
        <div className="footer-block">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref} className="footer-video --landscape">
                <BasicVideo
                  playVideo={inView}
                  src={FooterOneVideo}
                  poster={PosterOne}
                />
              </div>
            )}
          </InView>
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref} className="footer-video --landscape">
                <BasicVideo
                  playVideo={inView}
                  src={FooterTwoVideo}
                  poster={PosterTwo}
                />
              </div>
            )}
          </InView>
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref} className="footer-video --portrait">
                <BasicVideo
                  playVideo={inView}
                  src={FooterThreeVideo}
                  poster={PosterThree}
                />
              </div>
            )}
          </InView>
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref} className="footer-video --landscape">
                <BasicVideo
                  playVideo={inView}
                  src={FooterFourVideo}
                  poster={PosterFour}
                />
              </div>
            )}
          </InView>
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref} className="footer-video --portrait">
                <BasicVideo
                  playVideo={inView}
                  src={FooterFiveVideo}
                  poster={PosterFive}
                />
              </div>
            )}
          </InView>
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref} className="footer-video --portrait">
                <BasicVideo
                  playVideo={inView}
                  src={FooterSixVideo}
                  poster={PosterSix}
                />
              </div>
            )}
          </InView>
        </div>
      </div>
      {/* <img src={FooterBlock} className="footer-block" alt="Footer Block" /> */}
    </>
  )
}

Actions.propTypes = {
  toggleShowBookDemoModal: PropTypes.func,
}

export default Actions
