import React from 'react'
import PropTypes from 'prop-types'

import UILogoImage from 'images/landing-page/icons/live-red-icon.svg'
import UICommentImage from 'images/landing-page/ui-comment.png'
import UICardImage from 'images/landing-page/monetize-3.1.png'

const UIOverlay = ({ uiRef }) => (
  <div ref={uiRef} className="video-ui">
    <div className="ui-logo">
      <UILogoImage />
    </div>
    <div className="ui-comment">
      <img src={UICommentImage} alt="UI Comment Chat Bar" />
    </div>
    <div className="ui-card">
      <img src={UICardImage} alt="UI Featured Product Card" />
    </div>
  </div>
)

UIOverlay.propTypes = {
  uiRef: PropTypes.object,
}

export default UIOverlay
