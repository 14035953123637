import React, { useEffect, useRef } from 'react'
import cx from 'classnames'
import { isEmpty, isEqual, map } from 'lodash'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { InView } from 'react-intersection-observer'
import { gsap } from 'gsap'

import IconOne from 'images/landing-page/icons/monetize-1.svg'
import ImageOne from 'images/landing-page/monetize.png'
import TileOneOne from 'images/landing-page/monetize-tile.png'
import IconTwo from 'images/landing-page/icons/monetize-2.svg'
import ImageTwo from 'images/landing-page/monetize-2.png'
import IconThree from 'images/landing-page/icons/monetize-3.svg'
import ImageThree from 'images/landing-page/monetize-3.png'
import IconFour from 'images/landing-page/icons/monetize-4.svg'
import ImageFour from 'images/landing-page/monetize-4.png'
import TileFourOne from 'images/landing-page/monetize-tile-4-1.png'
import TileFourTwo from 'images/landing-page/monetize-tile-4-2.png'
import TileFourThree from 'images/landing-page/monetize-tile-4-3.png'
import TileFourFour from 'images/landing-page/monetize-tile-4-4.png'
import TileFourFive from 'images/landing-page/monetize-tile-4-5.png'
import AccordionItem from 'components/AccordionItem'
import useAccordionState from 'components/AccordionItem/useAccordionState'
import { SECTION_IDS, TAB_NUMBER } from '../constants'

const { ONE, TWO, THREE, FOUR, FIVE } = TAB_NUMBER
const MONETIZE_ACCORDION_CHANGE_DELAY = 2750

const Monetize = () => {
  const breakpoints = useBreakpoint()
  const [
    currentIndex,
    onAccordionChange,
    onAccordionHoverChange,
  ] = useAccordionState(3, MONETIZE_ACCORDION_CHANGE_DELAY)
  const timelineRef = useRef(null)

  const items = [
    {
      index: 0,
      icon: <IconOne />,
      title: 'Shoppable Tiles',
      description:
        'Customers can add to cart directly from shoppable tiles directly in the live stream. Increase conversion rate and drive more sales.',
    },
    {
      index: 1,
      icon: <IconTwo />,
      title: 'Integrate With Your Ecommerce Platform',
      description:
        'Sync inventory, product photos and pricing. LIVE is compatible with Shopify, Salesforce and other leading ecommerce platforms.',
    },
    {
      index: 2,
      icon: <IconThree />,
      title: 'Embed On Your Online Store',
      description:
        'We make sure your LIVE shopping experience is on brand. Customers never leave your store. Embed on your site with 6 lines of code.',
    },
    {
      index: 3,
      icon: <IconFour />,
      title: 'Build New Sales Channels',
      description:
        'Sell tickets for live events or launch a monthly subscription. Confidently build a revenue-generating live streaming program.',
    },
  ]

  const tilesData = {
    [ONE]: [{ image: TileOneOne, alt: 'Shoes checkout tile', position: ONE }],
    [FOUR]: [
      { image: TileFourOne, alt: 'Event tickets tile', position: ONE },
      { image: TileFourTwo, alt: 'Guide download tile', position: TWO },
      { image: TileFourThree, alt: 'Video tile', position: THREE },
      { image: TileFourFour, alt: 'Add to cart tile', position: FOUR },
      { image: TileFourFive, alt: 'Shoes checkout tile', position: FIVE },
    ],
  }

  useEffect(() => {
    timelineRef.current = gsap
      .timeline({
        delay: 0.5,
        defaults: {
          duration: 0.3,
        },
      })
      .fromTo(
        `.monetize-${ONE}`,
        {
          opacity: 0,
          scale: 0,
        },
        {
          opacity: 1,
          scale: 1,
          transformOrigin: 'center center',
        },
      )

    return () => {
      if (timelineRef.current) {
        timelineRef.current.kill()
      }
    }
  }, [])

  useEffect(() => {
    if (isEqual(currentIndex, 0) && !isEmpty(timelineRef.current)) {
      timelineRef.current.restart(true)
    }
  }, [currentIndex])

  function renderItems() {
    return map(items, ({ icon, title, description, index }) => (
      <AccordionItem
        key={`monetize_${index}`}
        index={index}
        onSelect={onAccordionChange}
        onHoverChange={onAccordionHoverChange}
        isActive={currentIndex === index}
      >
        <div className="item-icon">{icon}</div>
        <div className="section-item-container">
          <h4>{title}</h4>
          <p>{description}</p>
        </div>
      </AccordionItem>
    ))
  }

  function renderCards() {
    return map(items, ({ icon, title, description }) => (
      <InView threshold="0.2" key={`card_${title}`}>
        {({ inView, ref }) => (
          <li
            ref={ref}
            className={cx('section-list-item --accordion --mobile is-active', {
              'is-visible': inView,
            })}
          >
            <div className="item-icon">{icon}</div>
            <div className="section-item-container">
              <h4>{title}</h4>
              <p>{description}</p>
            </div>
          </li>
        )}
      </InView>
    ))
  }

  function renderTiles(key) {
    return map(tilesData[key], ({ image, alt, position }) => (
      <img
        className={`monetize-tile monetize-${key} ${position}`}
        key={position}
        src={image}
        alt={alt}
      />
    ))
  }

  return (
    <>
      <div className="rect --monetize" />
      <section
        className="section --monetize"
        id={SECTION_IDS.PRODUCTS.MONETIZE.id}
      >
        <div className="section-wrapper">
          <div className="section-container">
            <div className="section-content">
              <h4 className="section-label">Monetize</h4>
              <h2 className="section-subheading">
                Online Shopping, Reimagined.
              </h2>
              <p className="section-description">
                Drive incremental sales from your loyal customers and reduce
                your return rates.
              </p>
              <ul className="section-list">
                {breakpoints.md ? renderItems() : renderCards()}
              </ul>
            </div>
            <div className="section-animation --accordion">
              <div
                className={cx('animation-container', {
                  'is-active': currentIndex === 0,
                })}
              >
                <div className="image-container one">
                  <img
                    className="--main-image"
                    src={ImageOne}
                    alt="Online Shopping, Reimagined"
                  />
                  {renderTiles(ONE)}
                </div>
              </div>
              <div
                className={cx('animation-container', {
                  'is-active': currentIndex === 1,
                })}
              >
                <div className="image-container two">
                  <img
                    className="--main-image"
                    src={ImageTwo}
                    alt="Online Shopping, Reimagined"
                  />
                </div>
              </div>
              <div
                className={cx('animation-container', {
                  'is-active': currentIndex === 2,
                })}
              >
                <div className="image-container three">
                  <img
                    className="--main-image"
                    src={ImageThree}
                    alt="Online Shopping, Reimagined"
                  />
                </div>
              </div>
              <div
                className={cx('animation-container', {
                  'is-active': currentIndex === 3,
                })}
              >
                <div className="image-container four">
                  <img
                    className="--main-image"
                    src={ImageFour}
                    alt="Online Shopping, Reimagined"
                  />
                  {renderTiles(FOUR)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Monetize
