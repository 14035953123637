import React, { useState, useEffect } from 'react'
import { concat } from 'lodash'

import LayoutLandingPage from 'components/LayoutLandingPage'
import SEO from 'components/SEO'
import Header from 'components/Header'
import StickyHeader from 'components/StickyHeader'
import SectionMonetize from 'components/LandingPage/SectionMonetize'
import SectionSuccess from 'components/LandingPage/SectionSuccess'
import SectionEngage from 'components/LandingPage/SectionEngage'
import SectionCases from 'components/LandingPage/SectionCases'
import SectionIntegration from 'components/LandingPage/SectionIntegration'
import SectionActions from 'components/LandingPage/SectionActions'
import BookDemoModal from 'components/BookDemoModal'

const IndexPage = () => {
  const [isLoaded, setLoaded] = useState(false)
  const [loadedVideos, setLoadedVideos] = useState([])
  const [bookDemoModalState, setBookDemoModalState] = useState({
    visible: false,
    source: null,
  })

  useEffect(() => {
    if (loadedVideos.length >= 2) {
      setLoaded(true)
    }
  }, [loadedVideos])

  function onLoadedVideo(video) {
    setLoadedVideos(prevVideos => concat(prevVideos, video))
  }

  function toggleShowBookDemoModal(source = null) {
    setBookDemoModalState(currentState => ({
      visible: !currentState.visible,
      source,
    }))
  }

  return (
    <LayoutLandingPage isLoaded={isLoaded}>
      <SEO title="Sell With Live" />
      <StickyHeader toggleShowBookDemoModal={toggleShowBookDemoModal} />
      <div className="landing-page-sections">
        <Header
          onLoadedVideo={onLoadedVideo}
          isLoaded={isLoaded}
          toggleShowBookDemoModal={toggleShowBookDemoModal}
        />
        <SectionMonetize />
        <SectionSuccess />
        <SectionEngage />
        <SectionCases />
        <SectionIntegration />
        <SectionActions toggleShowBookDemoModal={toggleShowBookDemoModal} />
        <BookDemoModal
          visible={bookDemoModalState.visible}
          source={bookDemoModalState.source}
          toggleVisible={toggleShowBookDemoModal}
        />
      </div>
    </LayoutLandingPage>
  )
}

export default IndexPage
