import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, isNil } from 'lodash'

const Video = ({
  id,
  videoSrc,
  isAutoPlay,
  poster,
  videoRef,
  onLoadedVideo,
  isLoaded,
  onRestartVideo = { onRestartVideo },
}) => {
  const [isPlaying, setPlaying] = useState(false)

  useEffect(() => {
    if (!isNil(videoRef) && !isEmpty(videoRef.current)) {
      videoRef.current.load()
      videoRef.current.addEventListener('canplay', onVideoReady, false)
      videoRef.current.addEventListener('ended', onVideoEnded, false)
    }
  }, [videoRef])

  useEffect(() => {
    if (!isNil(videoRef) && !isAutoPlay && isLoaded && !isPlaying) {
      videoRef.current.play()
      setPlaying(true)
    }
  }, [isLoaded, isAutoPlay])

  function onVideoReady() {
    if (videoRef.current.readyState >= 2) {
      onLoadedVideo({
        id,
      })
    }
  }

  function onVideoEnded() {
    onRestartVideo()
  }

  return (
    <video
      className="video"
      ref={videoRef}
      playsInline
      muted
      autoPlay={isAutoPlay}
      // preload={isAutoPlay ? 'auto' : 'metadata'}
      src={videoSrc}
      poster={poster}
    />
  )
}

Video.propTypes = {
  videoRef: PropTypes.object,
  videoSrc: PropTypes.string,
  id: PropTypes.string,
  poster: PropTypes.string,
  isAutoPlay: PropTypes.bool,
  isLoaded: PropTypes.bool,
  onLoadedVideo: PropTypes.func,
  onRestartVideo: PropTypes.func,
}

export default Video
