import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import BackgroundHero from 'components/BackgroundHero'
import Phone from 'components/Phone'
import { GA_EVENT_SOURCES } from 'components/LandingPage/constants'

const Header = ({ isLoaded, onLoadedVideo, toggleShowBookDemoModal }) => {
  function handleBookDemo() {
    toggleShowBookDemoModal(GA_EVENT_SOURCES.HEADER)
  }

  return (
    <header className="header">
      <div className="header-background">
        <BackgroundHero onLoadedVideo={onLoadedVideo} isLoaded={isLoaded} />
      </div>
      <div className="header-container">
        <div className="heading-container">
          <h1 className="heading">Live Video Shopping for Ecommerce Brands</h1>
          <p>
            The world&apos;s most powerful live shopping platform. Engage your
            community. Increase conversion rates. Drive incremental sales.
          </p>
          <div className="header-actions">
            <Link
              className="button --primary"
              to="/signup"
              state={{ srouce: GA_EVENT_SOURCES.HEADER }}
            >
              Get Started
            </Link>
            <button
              className="button --secondary"
              onClick={handleBookDemo}
              type="button"
            >
              Book a Demo
            </button>
          </div>
        </div>
        <div className="header-animation">
          <Phone isLoaded={isLoaded} onLoadedVideo={onLoadedVideo} />
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  isLoaded: PropTypes.bool,
  onLoadedVideo: PropTypes.func,
  toggleShowBookDemoModal: PropTypes.func,
}

export default Header
