import React from 'react'
import { InView } from 'react-intersection-observer'
import cx from 'classnames'

const Success = () => (
  <>
    <div className="rect --success" />
    <section className="section --success">
      <div className="section-wrapper">
        <div className="section-container --no-grid">
          <div className="section-content">
            <h4 className="section-label --center">Success story</h4>
            <h2 className="section-subheading --center --large">
              Boost engagement and sales with video delivered by LIVE
            </h2>
            <ul className="section-list --inline">
              <InView>
                {({ inView, ref }) => (
                  <li
                    ref={ref}
                    className={cx('section-list-item --box', {
                      'is-active': inView,
                    })}
                  >
                    <h3>13</h3>
                    <p>Average minutes spent watching LIVE shopping events</p>
                  </li>
                )}
              </InView>
              <InView>
                {({ inView, ref }) => (
                  <li
                    ref={ref}
                    className={cx('section-list-item --box', {
                      'is-active': inView,
                    })}
                  >
                    <h3>25%</h3>
                    <p>
                      Conversion rate of LIVE shopping events to making a
                      purchase
                    </p>
                  </li>
                )}
              </InView>
              <InView>
                {({ inView, ref }) => (
                  <li
                    ref={ref}
                    className={cx('section-list-item --box', {
                      'is-active': inView,
                    })}
                  >
                    <h3>4X</h3>
                    <p>
                      More revenue generated per minute from LIVE shopping vs
                      Online Store
                    </p>
                  </li>
                )}
              </InView>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </>
)

export default Success
