import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import { isEmpty, map } from 'lodash'
import cx from 'classnames'
import axios from 'axios'

import TierItem from 'components/Tieritem'
import endpoints from 'endpoints'
import requestURL from 'utils/requestURL'
import { FEATURES } from './constants'

const Tiers = () => {
  const [subscriptionTiers, setSubscriptionTiers] = useState(null)
  const { results: tiers } = subscriptionTiers || {}

  useEffect(() => {
    axios
      .get(requestURL(endpoints.subscriptionPlans))
      .then(response => {
        const { data } = response
        if (!isEmpty(data)) {
          setSubscriptionTiers(data)
        }
      })
      .catch(errors => {
        throw errors
      })
  }, [])

  return (
    <>
      <section className="section --tiers">
        <Row className="tiers-container">
          {map(tiers, (tier, index) => (
            <Col
              key={tier.name}
              className={cx('tier-item', {
                'is-best-value': tier.is_best_value,
              })}
              xs={24}
              md={12}
            >
              <TierItem
                isLandingPage
                tier={tier}
                previousTierName={index > 0 ? tiers[index - 1].name : ''}
              />
            </Col>
          ))}
        </Row>
        <Row className="features-container">
          {map(FEATURES, ({ text, Icon }) => (
            <Col key={text} xs={24} sm={11} lg={7}>
              <Icon />
              {text}
            </Col>
          ))}
        </Row>
      </section>
    </>
  )
}

export default Tiers
