import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const PageLoader = ({ isLoaded }) => (
  <div className={cx('page-loader', { 'is-hidden': isLoaded })}>
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 100 100">
      <defs>
        <clipPath id="liveTextLoader">
          <rect x="100" y="0" width="100" height="100" />
        </clipPath>
      </defs>
      <g x="50%" y="50%" dy=".35em" width="88" height="45">
        <path
          d="M31.1499 8.64012C30.429 8.64012 29.6832 8.36563 28.9338 7.82317C28.3521 7.40294 27.6579 6.59539 27.6579 5.13869C27.6579 3.66536 28.3521 2.8542 28.9342 2.43324C30.4477 1.33891 31.9003 1.35917 33.3861 2.43361C33.9682 2.85456 34.662 3.66572 34.662 5.13869C34.662 6.59503 33.9682 7.40221 33.3861 7.82317C32.6367 8.36563 31.8841 8.64012 31.1499 8.64012Z"
          fill="#0E1826"
        />
        <path
          d="M1.97656 8.64015V41.4002H22.9888V35.1871H8.63527V8.64015H1.97656Z"
          fill="#0E1826"
        />
        <path
          d="M20.654 13.3201V19.0672H27.8955V41.4001H41.6662V35.6522H34.4022V13.3201H20.654Z"
          fill="#0E1826"
        />
        <path
          d="M55.1818 32.2576L46.6738 13.3201H39.3314L51.9841 41.4001H58.009L55.1818 32.2576Z"
          fill="#0E1826"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66.7354 29.2516C66.793 33.0912 68.6932 36.1854 73.7605 36.1854C77.3308 36.1854 78.6549 34.5807 79.4607 32.6896H85.795C84.9311 37.2745 80.6124 41.4001 73.7605 41.4001C64.7199 41.4001 60.3439 34.6385 60.3439 27.3601C60.3439 19.3372 65.1234 13.3201 73.588 13.3201C80.5551 13.3201 86.0255 18.5349 86.0255 25.3543C86.0255 26.2718 86.0255 27.5322 85.7373 29.2516H66.7354ZM79.9217 24.6093C79.9217 20.8845 77.2153 18.5349 73.4145 18.5349C69.7294 18.5349 66.7928 20.6554 66.7928 24.6093H79.9217Z"
          fill="#0E1826"
        />
      </g>
      <g
        x="50%"
        y="50%"
        dy=".35em"
        width="88"
        height="45"
        clipPath="url(#liveTextLoader)"
        fill="#E8386D"
      >
        <path
          d="M31.1499 8.64012C30.429 8.64012 29.6832 8.36563 28.9338 7.82317C28.3521 7.40294 27.6579 6.59539 27.6579 5.13869C27.6579 3.66536 28.3521 2.8542 28.9342 2.43324C30.4477 1.33891 31.9003 1.35917 33.3861 2.43361C33.9682 2.85456 34.662 3.66572 34.662 5.13869C34.662 6.59503 33.9682 7.40221 33.3861 7.82317C32.6367 8.36563 31.8841 8.64012 31.1499 8.64012Z"
          fill="#E8386D"
        />
        <path
          d="M1.97656 8.64015V41.4002H22.9888V35.1871H8.63527V8.64015H1.97656Z"
          fill="#E8386D"
        />
        <path
          d="M20.654 13.3201V19.0672H27.8955V41.4001H41.6662V35.6522H34.4022V13.3201H20.654Z"
          fill="#E8386D"
        />
        <path
          d="M55.1818 32.2576L46.6738 13.3201H39.3314L51.9841 41.4001H58.009L55.1818 32.2576Z"
          fill="#E8386D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66.7354 29.2516C66.793 33.0912 68.6932 36.1854 73.7605 36.1854C77.3308 36.1854 78.6549 34.5807 79.4607 32.6896H85.795C84.9311 37.2745 80.6124 41.4001 73.7605 41.4001C64.7199 41.4001 60.3439 34.6385 60.3439 27.3601C60.3439 19.3372 65.1234 13.3201 73.588 13.3201C80.5551 13.3201 86.0255 18.5349 86.0255 25.3543C86.0255 26.2718 86.0255 27.5322 85.7373 29.2516H66.7354ZM79.9217 24.6093C79.9217 20.8845 77.2153 18.5349 73.4145 18.5349C69.7294 18.5349 66.7928 20.6554 66.7928 24.6093H79.9217Z"
          fill="#E8386D"
        />
      </g>
    </svg>
  </div>
)

PageLoader.propTypes = {
  isLoaded: PropTypes.bool,
}

export default PageLoader
