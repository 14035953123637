import React from 'react'
import { InView } from 'react-intersection-observer'
import cx from 'classnames'

// import AliceOliviaLogo from 'images/landing-page/alice-olivia-logo.png'
import VideoIcon from 'images/landing-page/icons/video-icon.svg'
import LiveIcon from 'images/landing-page/icons/live-icon.svg'
import { SECTION_IDS } from '../constants'

const Engage = () => (
  <>
    <div className="rect --engage" />
    <section className="section --engage" id={SECTION_IDS.PRODUCTS.ENGAGE.id}>
      <div className="section-wrapper">
        <div className="section-container">
          <div className="section-content">
            <h4 className="section-label">Engage</h4>
            <h2 className="section-subheading">
              Engage Your Audience Like Never Before
            </h2>
            <p className="section-description">
              LIVE is the future of content and commerce. Connect directly with
              your customers. Watch as your products, events and programming
              come to life.
            </p>
          </div>
        </div>
      </div>
      <div className="section-wrapper">
        <div className="section-container --no-grid">
          <div className="section-content">
            <ul className={cx('section-list --inline')}>
              <InView>
                {({ inView, ref }) => (
                  <li
                    ref={ref}
                    className={cx('section-list-item --box --detail', {
                      'is-active': inView,
                    })}
                  >
                    <VideoIcon />
                    <h3>On Demand Videos</h3>
                    <p>
                      Customers can watch videos or shop even after your live
                      stream has ended. Easily manage and organize your videos
                      using our CMS.
                    </p>
                  </li>
                )}
              </InView>
              <InView>
                {({ inView, ref }) => (
                  <li
                    ref={ref}
                    className={cx('section-list-item --box --detail', {
                      'is-active': inView,
                    })}
                  >
                    <LiveIcon />
                    <h3>Multi-Cast</h3>
                    <p>
                      Have different events going on at once? No problem. Launch
                      multiple live streams at the same time.
                    </p>
                  </li>
                )}
              </InView>
            </ul>
            {/* <figure className="section-blockquote">
              <img src={AliceOliviaLogo} alt="Alice and Olivia Logo" />
              <blockquote>
                <p>
                  “We quadrupled our sales in the space of 30 minutes while live
                  streaming using LIVE”{' '}
                </p>
                <figcaption>
                  Alice & Olivia <cite>by Stacey Bendet</cite>
                </figcaption>
              </blockquote>
            </figure> */}
          </div>
        </div>
      </div>
    </section>
  </>
)

export default Engage
