import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import PlusIcon from 'images/landing-page/icons/plus.svg'

const Card = ({
  id,
  cardRef,
  title,
  description,
  image,
  price,
  old_price: oldPrice,
  isActive,
  isSelected,
}) => (
  <div id={id} ref={cardRef} className={cx('card', { 'is-active': isActive })}>
    <div className="card-container">
      <div className="product-details">
        <h4>{title}</h4>
        <p>{description}</p>
        <button type="button" className={cx({ 'is-active': isSelected })}>
          <PlusIcon />
          Add to cart
        </button>
      </div>
      <div className="product-details --image">
        <div className="product-image">
          <img src={image} alt={title} />
          <div className="product-price">
            {oldPrice && (
              <span>
                <s>{oldPrice}</s>
              </span>
            )}
            <span>{price}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
)

Card.propTypes = {
  id: PropTypes.string,
  isActive: PropTypes.bool,
  isSelected: PropTypes.bool,
  cardRef: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  price: PropTypes.string,
  old_price: PropTypes.string,
}

export default Card
