import React, { useState, useEffect, useRef } from 'react'
import cx from 'classnames'
import { Link } from 'gatsby'
import { isNil, throttle, isEmpty, map } from 'lodash'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { Drawer } from 'antd'
import Popup from 'reactjs-popup'
import PropTypes from 'prop-types'

import LiveLogo from 'images/landing-page/icons/live-red-icon.svg'
import HamburgerIcon from 'images/icons/hamburger.svg'
import { GA_EVENT_SOURCES, SECTION_IDS } from 'components/LandingPage/constants'

const StickyHeader = ({ toggleShowBookDemoModal }) => {
  const breakpoints = useBreakpoint()
  const headerRef = useRef(null)
  const [isActive, setActive] = useState(false)
  const [isBlurred, setBlurred] = useState(false)
  const [showDrawer, setShowDrawer] = useState(false)
  const popupRef = useRef()
  const [showTooltip, setShowTooltip] = useState(false)

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!isNil(headerRef.current)) {
      window.addEventListener('scroll', throttle(onScroll, 16), false)

      return () => {
        window.removeEventListener('scroll', throttle(onScroll, 16), false)
        setActive(false)
      }
    }
  }, [])

  function onScroll() {
    if (!isEmpty(headerRef.current)) {
      if (
        window.scrollY + headerRef.current.offsetHeight >
        window.innerHeight
      ) {
        setBlurred(false)
        setActive(true)
      } else if (window.scrollY >= headerRef.current.offsetHeight / 2) {
        setBlurred(true)
        setActive(false)
      } else {
        setBlurred(false)
      }
    }
  }

  function toggleShowDrawer() {
    setShowDrawer(currentState => !currentState)
  }

  function toggleShowTooltip() {
    if (breakpoints.m) {
      setShowTooltip(currentState => !currentState)
    }
  }

  function scrollToSection(event) {
    const SECTION_ELEMENT = document.getElementById(
      event.currentTarget.getAttribute('scrollto'),
    )

    if (SECTION_ELEMENT) {
      SECTION_ELEMENT.scrollIntoView()
    }
    if (showDrawer) {
      toggleShowDrawer()
    }

    if (popupRef.current) {
      popupRef.current.close()
    } else {
      toggleShowTooltip()
    }
  }

  function handleBookDemo() {
    toggleShowDrawer()
    toggleShowBookDemoModal(GA_EVENT_SOURCES.NAV)
  }

  function renderProductButtonContainer() {
    return (
      <div className="product-buttons-conainer">
        {map(SECTION_IDS.PRODUCTS, ({ id, label, Icon }) => (
          <button
            key={id}
            type="button"
            className="product-button"
            onClick={scrollToSection}
            scrollto={id}
          >
            <Icon />
            {label}
          </button>
        ))}
      </div>
    )
  }

  function renderProductsLink() {
    const tooltipTrigger = (
      <button
        type="button"
        className={cx('section-link-button', {
          '--primary': isActive,
          '--secondary': !isActive,
        })}
        onClick={toggleShowTooltip}
      >
        Products
      </button>
    )

    if (breakpoints.m) {
      return (
        <>
          {tooltipTrigger}
          <div className={cx('tooltip-container', { '--open': showTooltip })}>
            {renderProductButtonContainer()}
          </div>
        </>
      )
    }

    return (
      <Popup
        ref={popupRef}
        trigger={tooltipTrigger}
        className="products-tooltip"
      >
        {renderProductButtonContainer()}
      </Popup>
    )
  }

  function renderNavOptions() {
    return (
      <nav className="navigation">
        <div>
          {renderProductsLink()}
          {map(SECTION_IDS, ({ id, label }) => {
            if (id) {
              return (
                <button
                  key={id}
                  type="button"
                  onClick={scrollToSection}
                  scrollto={id}
                  className={cx('section-link-button', {
                    '--primary': isActive,
                    '--secondary': !isActive,
                  })}
                >
                  {label}
                </button>
              )
            }
            return null
          })}
        </div>
        <div className="cta-buttons-container">
          <Link
            className={cx('button nav-button', {
              '--secondary': !isActive,
              '--primary': isActive,
            })}
            to="/signup"
            state={{ srouce: GA_EVENT_SOURCES.NAV }}
          >
            Get Started
          </Link>
          <button
            className={cx('button book-demo-button', {
              '--primary': isActive,
              '--secondary': !isActive,
            })}
            onClick={handleBookDemo}
            type="button"
          >
            Book a Demo
          </button>
        </div>
      </nav>
    )
  }

  function renderNavigationItems() {
    if (breakpoints.m) {
      return (
        <>
          <button
            className={cx('menu-button', { '--secondary': isActive })}
            type="button"
            onClick={() => {
              setShowDrawer(true)
            }}
          >
            <HamburgerIcon />
          </button>
          <Drawer
            className="nav-drawer"
            closable
            visible={showDrawer}
            onClose={toggleShowDrawer}
            size="large"
          >
            {renderNavOptions()}
          </Drawer>
        </>
      )
    }

    return renderNavOptions()
  }

  return (
    <header
      ref={headerRef}
      className={cx(
        {
          'is-active': isActive,
          'is-blurred': isBlurred,
        },
        'sticky-header',
      )}
    >
      <div className="logo">
        <Link to="/">
          <LiveLogo />
        </Link>
      </div>
      {renderNavigationItems()}
    </header>
  )
}

StickyHeader.propTypes = {
  toggleShowBookDemoModal: PropTypes.func,
}

export default StickyHeader
