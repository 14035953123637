import React, { useEffect, useRef } from 'react'
import cx from 'classnames'
import { keys, map } from 'lodash'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { gsap } from 'gsap'

import IconOne from 'images/landing-page/icons/cases-1.svg'
import ImageOne from 'images/landing-page/cases-1.png'
import TileOneOne from 'images/landing-page/cases-tile-1-1.png'
import TileOneTwo from 'images/landing-page/cases-tile-1-2.png'
import TileOneThree from 'images/landing-page/cases-tile-1-3.png'
import TileOneFour from 'images/landing-page/cases-tile-1-4.png'
import IconTwo from 'images/landing-page/icons/cases-2.svg'
import ImageTwo from 'images/landing-page/cases-2.png'
import TileTwoOne from 'images/landing-page/cases-tile-2-1.png'
import TileTwoTwo from 'images/landing-page/cases-tile-2-2.png'
import TileTwoThree from 'images/landing-page/cases-tile-2-3.png'
import IconThree from 'images/landing-page/icons/cases-3.svg'
import ImageThree from 'images/landing-page/cases-3.png'
import TileThreeOne from 'images/landing-page/cases-tile-3-1.png'
import TileThreeTwo from 'images/landing-page/cases-tile-3-2.png'
import TileThreeThree from 'images/landing-page/cases-tile-3-3.png'
import TileThreeFour from 'images/landing-page/cases-tile-3-4.png'
import IconFour from 'images/landing-page/icons/cases-4.svg'
import ImageFour from 'images/landing-page/cases-4.png'
import TileFourOne from 'images/landing-page/cases-tile-4-1.png'
import TileFourTwo from 'images/landing-page/cases-tile-4-2.png'
import TileFourThree from 'images/landing-page/cases-tile-4-3.png'
import TileFourFour from 'images/landing-page/cases-tile-4-4.png'
import AccordionItem from 'components/AccordionItem'
import useAccordionState from 'components/AccordionItem/useAccordionState'
import { SECTION_IDS, TAB_NUMBER } from '../constants'

const { ONE, TWO, THREE, FOUR } = TAB_NUMBER
const CASES_ACCORDION_CHANGE_DELAY = 5000

const Cases = () => {
  const breakpoints = useBreakpoint()
  const [
    currentIndex,
    onAccordionChange,
    onAccordionHoverChange,
  ] = useAccordionState(3, CASES_ACCORDION_CHANGE_DELAY)
  const timelineRef = useRef(null)

  const items = [
    {
      index: 0,
      icon: <IconOne />,
      title: 'Ecommerce',
    },
    {
      index: 1,
      icon: <IconTwo />,
      title: 'Fitness',
    },
    {
      index: 2,
      icon: <IconThree />,
      title: 'Entertainment',
    },
    {
      index: 3,
      icon: <IconFour />,
      title: 'Education',
    },
  ]

  const tilesData = {
    [ONE]: [
      { image: TileOneOne, alt: 'Shoes checkout tile', position: ONE },
      { image: TileOneTwo, alt: 'See video tile', position: TWO },
      { image: TileOneThree, alt: 'Event tickets tile', position: THREE },
      { image: TileOneFour, alt: 'Guide download tile', position: FOUR },
    ],
    [TWO]: [
      { image: TileTwoOne, alt: 'Buy shoes tile', position: ONE },
      { image: TileTwoTwo, alt: 'Guide download tile', position: TWO },
      { image: TileTwoThree, alt: 'Event tickets tile', position: THREE },
    ],
    [THREE]: [
      {
        image: TileThreeOne,
        alt: 'Event tickets checkout tile',
        position: ONE,
      },
      { image: TileThreeTwo, alt: 'Buy shoes tile', position: TWO },
      { image: TileThreeThree, alt: 'See video tile', position: THREE },
      { image: TileThreeFour, alt: 'Guide download tile', position: FOUR },
    ],
    [FOUR]: [
      { image: TileFourOne, alt: 'Guide download large tile', position: ONE },
      { image: TileFourTwo, alt: 'See video tile', position: TWO },
      { image: TileFourThree, alt: 'Buy shoes tile', position: THREE },
      { image: TileFourFour, alt: 'Event tickets tile', position: FOUR },
    ],
  }

  useEffect(() => {
    timelineRef.current = gsap
      .timeline({
        delay: 0.5,
        defaults: {
          duration: 0.3,
        },
      })
      .fromTo(
        `.case-${TAB_NUMBER[keys(TAB_NUMBER)[currentIndex]]}`,
        {
          opacity: 0,
          scale: 0,
        },
        {
          opacity: 1,
          scale: 1,
          stagger: 0.4,
          transformOrigin: 'center center',
        },
      )

    return () => {
      if (timelineRef.current) {
        timelineRef.current.kill()
      }
    }
  }, [currentIndex])

  function renderItems() {
    return map(items, ({ icon, title, index }) => (
      <AccordionItem
        key={`cases_${index}`}
        index={index}
        onSelect={onAccordionChange}
        onHoverChange={onAccordionHoverChange}
        isActive={currentIndex === index}
        hasNoDescription
      >
        <div className="item-icon">{icon}</div>
        <div className="section-item-container">
          <h4>{title}</h4>
        </div>
      </AccordionItem>
    ))
  }

  function renderTiles(key) {
    return map(tilesData[key], ({ image, alt, position }) => (
      <img
        className={`cases-tile case-${key} ${position}`}
        key={position}
        src={image}
        alt={alt}
      />
    ))
  }

  function renderAnimation() {
    return (
      <div className="section-animation --accordion">
        <div
          className={cx('animation-container', {
            'is-active': currentIndex === 0,
          })}
        >
          <div className="image-container one">
            <img
              className="--main-image"
              src={ImageOne}
              alt="Online Shopping, Reimagined"
            />
            {renderTiles(ONE)}
          </div>
        </div>
        <div
          className={cx('animation-container', {
            'is-active': currentIndex === 1,
          })}
        >
          <div className="image-container two">
            <img
              className="--main-image"
              src={ImageTwo}
              alt="Online Shopping, Reimagined"
            />
            {renderTiles(TWO)}
          </div>
        </div>
        <div
          className={cx('animation-container', {
            'is-active': currentIndex === 2,
          })}
        >
          <div className="image-container three">
            <img
              className="--main-image"
              src={ImageThree}
              alt="Online Shopping, Reimagined"
            />
            {renderTiles(THREE)}
          </div>
        </div>
        <div
          className={cx('animation-container', {
            'is-active': currentIndex === 3,
          })}
        >
          <div className="image-container four">
            <img
              className="--main-image"
              src={ImageFour}
              alt="Online Shopping, Reimagined"
            />
            {renderTiles(FOUR)}
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="rect --white" />
      <section
        className="section --white --cases"
        id={SECTION_IDS.USE_CASES.id}
      >
        <div className="section-wrapper">
          <div className="section-container">
            <div className="section-content">
              <h4 className="section-label">Use Cases</h4>
              <h2 className="section-subheading">
                Helping Business Grow With LIVE Streaming
              </h2>
              {breakpoints.md ? null : renderAnimation()}
              <ul className="section-list">{renderItems()}</ul>
            </div>
            {breakpoints.md ? renderAnimation() : null}
          </div>
        </div>
      </section>
    </>
  )
}

export default Cases
