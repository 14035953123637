/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import 'styles/main.scss'

import PageLoader from 'components/PageLoader'
import Footer from 'components/Footer'

const LayoutLandingPage = ({ children, isLoaded }) => (
  <>
    <main
      className={cx('page', {
        'is-active': isLoaded,
      })}
    >
      {children}
    </main>
    <Footer />
    {!isLoaded && <PageLoader isLoaded={isLoaded} />}
  </>
)

LayoutLandingPage.propTypes = {
  children: PropTypes.node.isRequired,
  isLoaded: PropTypes.bool,
}

export default LayoutLandingPage
