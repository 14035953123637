import React, { useRef, useState, useLayoutEffect, useEffect } from 'react'
import { gsap } from 'gsap'
import { MotionPathPlugin } from 'gsap/MotionPathPlugin'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { isEmpty, map, debounce, isNil } from 'lodash'

import Video from 'components/Video'
import Card from 'components/Card'
import PhoneVideo from 'video/phone-video.mp4'
import LiveLogo from 'images/landing-page/icons/live-red-icon.svg'
import BagIcon from 'images/landing-page/icons/bag.svg'
import poster from 'video/phone-poster.jpg'
import ImageUI from 'images/landing-page/phone-ui.png'
import ProductOneImage from 'images/landing-page/products/product-1.png'
import ProductTwoImage from 'images/landing-page/products/product-2.png'
import ProductThreeImage from 'images/landing-page/products/product-3.png'
import ProductFourImage from 'images/landing-page/products/product-4.png'

gsap.registerPlugin(MotionPathPlugin)

const Phone = ({ isLoaded, onLoadedVideo }) => {
  const widthRef = useRef(null)
  const phoneRef = useRef(null)
  const videoRef = useRef(null)
  const bagRef = useRef(null)
  const cardOneRef = useRef(null)
  const cardTwoRef = useRef(null)
  const cardThreeRef = useRef(null)
  const cardFourRef = useRef(null)
  const timelineRef = useRef(null)
  const pulseRef = useRef(null)
  const countRef = useRef(null)
  const [isActive, setActive] = useState(null)
  const [isPulseActive, setPulseActive] = useState(null)
  const [isSelected, setSelected] = useState(null)
  const [count, setCount] = useState(0)

  const cards = [
    {
      index: 0,
      id: 'card-one',
      show: 2,
      select: 20,
      startPos: 20,
      image: ProductOneImage,
      cardRef: cardOneRef,
      title: 'Embroided Tracksuit',
      description:
        'It’s all power to the flower on this cropped, stepped-hem sweatshirt abloom in bright and bold embroidered and appliqued blossoms.',
      price: '$225',
    },
    {
      index: 1,
      id: 'card-two',
      show: 23,
      select: 31,
      startPos: -20,
      image: ProductTwoImage,
      cardRef: cardTwoRef,
      title: 'White Sneakers',
      description:
        'The white sneaker trend isn’t going away anytime soon, and with these featuring a vintage-inspired exaggerated tongue and heritage tongue logo.',
      old_price: '$145',
      price: '$119',
    },
    {
      index: 2,
      id: 'card-three',
      show: 35,
      select: 42,
      startPos: -20,
      image: ProductThreeImage,
      cardRef: cardThreeRef,
      title: 'Red Vest',
      description:
        'Embrace style with this seasons staple - women’s red vest top...',
      old_price: '$45',
      price: '$29',
    },
    {
      index: 3,
      id: 'card-four',
      show: 53,
      select: 60,
      startPos: 20,
      image: ProductFourImage,
      cardRef: cardFourRef,
      title: 'Knitted Sweater',
      description:
        'Add colour to your collection with this hand woven red knitted sweater…',
      price: '$179',
    },
  ]

  useLayoutEffect(() => {
    widthRef.current = window.innerWidth
    window.addEventListener('resize', debounce(onResizeCheck, 100), false)

    window.addEventListener('focus', () => {
      timelineRef.current.play(2)
      videoRef.current.play()
    })

    window.addEventListener('blur', () => {
      onRestartTimeline()
      timelineRef.current.pause()
      videoRef.current.pause()
      videoRef.current.currentTime = 2
    })

    // videoRef.current.addEventListener('playing', onRestartTimeline)
    if (!isEmpty(videoRef.current)) {
      gsap.defaults({ overwrite: 'auto' })
      timelineRef.current = gsap.timeline({
        duration: videoRef.current.duration,
      })
      // card one = right
      // card two = left
      // card three = right
      // card four = left
      timelineRef.current
        .set([cardOneRef.current, cardThreeRef.current], {
          x: 20,
          opacity: 0,
        })
        .set([cardTwoRef.current, cardFourRef.current], {
          x: -20,
          opacity: 0,
        })
    }

    return () => {
      window.removeEventListener('resize', debounce(onResizeCheck, 100), false)
      timelineRef.current.kill()
    }
  }, [])

  useEffect(() => {
    if (isLoaded) {
      onStartTimeline()
    }
  }, [isLoaded])

  function onResizeCheck() {
    if (!isNil(videoRef.current)) {
      if (widthRef.current !== window.innerWidth) {
        widthRef.current = window.innerWidth
        onResize()
      }
    }
  }

  function onRestartVideo() {
    /* eslint-disable no-param-reassign */
    videoRef.current.currentTime = 0
    /* eslint-enable no-param-reassign */
    videoRef.current.play()
    onRestartTimeline()
  }

  function onRestartTimeline() {
    setPulseActive(false)
    setCount(0)
    setActive(null)
    setSelected(null)
    timelineRef.current.restart()
  }

  function onResize() {
    const reset = {
      y: 0,
      width: '100%',
      height: 'auto',
      opacity: 0,
      borderRadius: '16px',
    }
    const currentProgress = timelineRef.current.progress()
    timelineRef.current.clear()
    timelineRef.current.invalidate()
    timelineRef.current.kill()

    timelineRef.current = gsap.timeline()
    timelineRef.current.set(
      [
        cardOneRef.current,
        cardThreeRef.current,
        cardTwoRef.current,
        cardFourRef.current,
      ],
      { clearProps: 'all' },
    )
    timelineRef.current
      .set([cardOneRef.current, cardThreeRef.current], {
        x: 20,
        ...reset,
      })
      .set([cardTwoRef.current, cardFourRef.current], {
        x: -20,
        ...reset,
      })
    onStartTimeline(currentProgress)
  }

  function onStartTimeline(currentProgress) {
    const CARD_SELECT_OFFSET = 0.8
    const moveCard = {
      width: '20px',
      height: '20px',
      borderRadius: '4px',
      opacity: 1,
      ease: 'power2.out',
    }
    const fadeIn = {
      duration: 0.5,
      x: 0,
      opacity: 1,
    }
    timelineRef.current
      .to(cardOneRef.current, { ...fadeIn }, cards[0].show)
      .to(
        cardOneRef.current,
        {
          onStart: () => {
            setSelected(0)
          },
        },
        cards[0].select - CARD_SELECT_OFFSET,
      )
      .to(
        cardOneRef.current,
        {
          ...moveCard,
          duration: 0.7,
          motionPath: {
            path: [
              { x: 0, y: 0 },
              { x: -100, y: 0 },
              { x: -25, y: -(cardOneRef.current.offsetTop - 55) },
            ],
          },
          right: 0,
          onStart: () => {
            setActive(0)
          },
        },
        cards[0].select,
      )
      .to(
        cardOneRef.current,
        {
          opacity: 0,
          duration: 0.25,
          onComplete: () => {
            setCount(1)
            setPulseActive(true)
          },
        },
        '-=0.2',
      )
      // Card two start
      .to(
        cardTwoRef.current,
        {
          ...fadeIn,
          onComplete: () => {
            setPulseActive(false)
          },
        },
        cards[1].show,
      )
      .to(
        cardTwoRef.current,
        {
          onStart: () => {
            setSelected(1)
          },
        },
        cards[1].select - CARD_SELECT_OFFSET,
      )
      .to(
        cardTwoRef.current,
        {
          ...moveCard,
          duration: 0.7,
          left: 0,
          motionPath: {
            path: [
              { x: 0, y: 0 },
              {
                x: phoneRef.current.offsetWidth / 2 + 60,
                y: -(cardTwoRef.current.offsetTop / 4),
              },
              {
                x: phoneRef.current.offsetWidth - 45,
                y: -(cardTwoRef.current.offsetTop - 55),
              },
            ],
          },
          onStart: () => {
            setActive(1)
          },
        },
        cards[1].select,
      )
      .to(
        cardTwoRef.current,
        {
          opacity: 0,
          delay: 0.25,
          duration: 0.25,
          onComplete: () => {
            setCount(2)
            setPulseActive(true)
          },
        },
        '-=0.5',
      )
      // Card Three Start
      .to(
        cardThreeRef.current,
        {
          ...fadeIn,
          onComplete: () => {
            setPulseActive(false)
          },
        },
        cards[2].show,
      )
      .to(
        cardThreeRef.current,
        {
          onStart: () => {
            setSelected(2)
          },
        },
        cards[2].select - CARD_SELECT_OFFSET,
      )
      .to(
        cardThreeRef.current,
        {
          ...moveCard,
          duration: 0.7,
          right: 0,
          motionPath: {
            path: [
              { x: 0, y: 0 },
              { x: -100, y: 0 },
              { x: -25, y: -(cardThreeRef.current.offsetTop - 55) },
            ],
          },
          onStart: () => {
            setActive(2)
          },
        },
        cards[2].select,
      )
      .to(
        cardThreeRef.current,
        {
          opacity: 0,
          delay: 0.25,
          duration: 0.25,
          onComplete: () => {
            setCount(3)
            setPulseActive(true)
          },
        },
        '-=0.5',
      )
      // Card Four Start
      .to(
        cardFourRef.current,
        {
          ...fadeIn,
          onComplete: () => {
            setPulseActive(false)
          },
        },
        cards[3].show,
      )
      .to(
        cardFourRef.current,
        {
          onStart: () => {
            setSelected(3)
          },
        },
        cards[3].select - CARD_SELECT_OFFSET,
      )
      .to(
        cardFourRef.current,
        {
          ...moveCard,
          duration: 0.7,
          left: 0,
          motionPath: {
            path: [
              { x: 0, y: 0 },
              {
                x: phoneRef.current.offsetWidth / 2 + 60,
                y: -(cardFourRef.current.offsetTop / 4),
              },
              {
                x: phoneRef.current.offsetWidth - 45,
                y: -(cardFourRef.current.offsetTop - 55),
              },
            ],
          },
          onStart: () => {
            setActive(3)
          },
        },
        cards[3].select,
      )
      .to(
        cardFourRef.current,
        {
          opacity: 0,
          delay: 0.25,
          duration: 0.25,
          onComplete: () => {
            setCount(4)
            setPulseActive(true)
          },
        },
        '-=0.5',
      )

    if (currentProgress) {
      timelineRef.current.progress(currentProgress)
    }
  }

  function renderCards() {
    /* eslint-disable react/jsx-props-no-spreading */
    return map(cards, card => (
      <Card
        {...card}
        key={card.id}
        isSelected={isSelected === card.index}
        isActive={isActive === card.index}
      />
    ))
    /* eslint-enable react/jsx-props-no-spreading */
  }

  return (
    <div className="phone-container">
      <div className="phone" ref={phoneRef}>
        <Video
          videoRef={videoRef}
          videoSrc={PhoneVideo}
          poster={poster}
          isLoaded={isLoaded}
          onLoadedVideo={onLoadedVideo}
          id="phone"
          onRestartVideo={onRestartVideo}
        />
        <div className="live-logo">
          <LiveLogo />
        </div>
        <div className="ui">
          <img src={ImageUI} alt="UI" />
        </div>
        <div ref={bagRef} className="bag">
          <BagIcon />
          <span
            ref={countRef}
            className={cx('count', { 'is-active': count > 0 })}
          >
            {count}
          </span>
        </div>
      </div>
      <div className="cards">
        {renderCards()}
        <div
          ref={pulseRef}
          className={cx('pulse-ring', {
            'is-active': isPulseActive,
          })}
        />
      </div>
    </div>
  )
}

Phone.propTypes = {
  isLoaded: PropTypes.bool,
  onLoadedVideo: PropTypes.func,
}

export default Phone
