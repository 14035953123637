import React from 'react'
import { Link } from 'gatsby'
// import PropTypes from 'prop-types'

/**
 * @todo - Hide privacy policy
 */
const Footer = () => (
  <footer className="footer">
    <p>© {`${new Date().getFullYear()}`} Live. All Rights Reserved.</p>
    <div className="footer-links">
      <Link to="/terms">Terms of use</Link>
      {/* {!hidePrivacy && <a href="mailto:hello@pbj.live">Privacy Policy</a>} */}
    </div>
  </footer>
)

// Footer.propTypes = {
//   hidePrivacy: PropTypes.bool,
// }

export default Footer
