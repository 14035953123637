import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import Video from 'components/Video'
import HeroVideo from 'video/hero-background.mp4'
import poster from 'video/background-poster.jpg'

const BackgroundHero = ({ isLoaded, onLoadedVideo }) => {
  const videoRef = useRef(null)

  useEffect(() => {
    window.addEventListener('focus', () => {
      videoRef.current.play()
    })

    window.addEventListener('blur', () => {
      videoRef.current.pause()
      videoRef.current.currentTime = 2
    })
  }, [])

  function onRestartVideo() {
    /* eslint-disable no-param-reassign */
    videoRef.current.currentTime = 0
    /* eslint-enable no-param-reassign */
    videoRef.current.play()
  }

  return (
    <div className="background-container">
      <Video
        videoSrc={HeroVideo}
        poster={poster}
        isLoaded={isLoaded}
        onLoadedVideo={onLoadedVideo}
        id="background"
        videoRef={videoRef}
        onRestartVideo={onRestartVideo}
      />
    </div>
  )
}

BackgroundHero.propTypes = {
  isLoaded: PropTypes.bool,
  onLoadedVideo: PropTypes.func,
}

export default BackgroundHero
