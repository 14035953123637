import FeatureOne from 'images/landing-page/icons/feature-1.svg'
import FeatureThree from 'images/landing-page/icons/feature-3.svg'
import FeatureFour from 'images/landing-page/icons/feature-4.svg'
import FeatureFive from 'images/landing-page/icons/feature-5.svg'
import FeatureSix from 'images/landing-page/icons/feature-6.svg'
import FeatureSeven from 'images/landing-page/icons/feature-7.svg'

export const FEATURES = [
  {
    text: 'Stream on your website, iOS or Android app',
    Icon: FeatureOne,
  },
  {
    text: 'Video on Demand',
    Icon: FeatureThree,
  },
  {
    text: 'Pre-recorded streams',
    Icon: FeatureFour,
  },
  {
    text: 'Simulcast to YouTube and Facebook',
    Icon: FeatureFive,
  },
  {
    text: 'Schedule Streams',
    Icon: FeatureSix,
  },
  {
    text: 'Broadcaster App',
    Icon: FeatureSeven,
  },
]
