import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { isNil } from 'lodash'

const BasicVideo = ({ src, poster, videoRef, playVideo }) => {
  const localVideoRef = useRef(null)

  useEffect(() => {
    if (playVideo && !isNil(localVideoRef.current)) {
      localVideoRef.current.load()
      localVideoRef.current.oncanplay = () => {
        localVideoRef.current.play()
      }
    }
  }, [playVideo])

  return (
    <video
      ref={!isNil(videoRef) ? videoRef : localVideoRef}
      playsInline
      muted
      loop
      preload="none"
      src={src}
      poster={poster}
    />
  )
}

BasicVideo.propTypes = {
  videoRef: PropTypes.object,
  src: PropTypes.string,
  poster: PropTypes.string,
  playVideo: PropTypes.bool,
}

export default BasicVideo
